import { z } from 'zod'

export const filterChooseOptionSchema = z.object({
	id: z.string(),
	name: z.string(),
	description: z.string().optional(),

	/**
	 * Indicates whether this filter option is recommended. Recommended options are typically displayed more prominently in the UI, suggesting they are commonly useful or popular.
	 */
	isRecommended: z.boolean().optional(),

	/**
	 * Option image for show in tooltips
	 */
	image: z.string().optional(),

	/**
	 * Option icon for show in recommended/other
	 */
	iconName: z.string().optional()
})

export type FilterChooseOption = z.infer<typeof filterChooseOptionSchema>
