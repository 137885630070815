import { z } from 'zod'

export enum FilterType {
	RANGE = 'RANGE',
	AND = 'AND',
	OR = 'OR',
	PRICE_RANGE = 'PRICE_RANGE',
	GROUP = 'GROUP'
}

export const FilterTypeEnum = z.nativeEnum(FilterType)
