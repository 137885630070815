import { z } from 'zod'

export const filterBaseSchema = z.object({
	id: z.string(),
	name: z.string(),
	description: z.string().optional(),
	image: z.string().optional(),
	iconName: z.string().optional()
})

export type FilterBase = z.infer<typeof filterBaseSchema>
