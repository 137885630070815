import { z } from 'zod'

import { filterBaseSchema } from './FilterBase.ts'
import { filterChooseOptionSchema } from './FilterChooseOption.ts'
import { filterRangeParamsSchema } from './FilterRangeParams.ts'
import { FilterType } from './FilterType.ts'

export const filterRangeSchema = filterBaseSchema.extend({
	type: z.literal(FilterType.RANGE),
	rangeParams: filterRangeParamsSchema.omit({ isMaxPlus: true }).optional()
})

export const filterPriceRangeSchema = filterBaseSchema.extend({
	type: z.literal(FilterType.PRICE_RANGE),
	rangeParams: filterRangeParamsSchema.optional()
})

export const filterChooseSchemaForAnd = filterBaseSchema.extend({
	type: z.literal(FilterType.AND),
	isAllowAll: z.boolean().optional(),
	options: z.array(filterChooseOptionSchema)
})

export const filterChooseSchemaForOr = filterBaseSchema.extend({
	type: z.literal(FilterType.OR),
	isAllowAll: z.boolean().optional(),
	options: z.array(filterChooseOptionSchema)
})

export const filterGroupSchema = filterBaseSchema.extend({
	type: z.literal(FilterType.GROUP),
	isDropdown: z.never().optional(),
	elements: z.array(
		z.union([
			filterRangeSchema,
			filterChooseSchemaForAnd,
			filterChooseSchemaForOr
		])
	)
})

/**
 * Temporaly disabled
 * We cannot validate this properly
 */
export const filterGroupDropdownSchema = filterBaseSchema.extend({
	type: z.literal(FilterType.GROUP),
	isDropdown: z.literal(true),
	elements: z.array(filterChooseSchemaForAnd, filterChooseSchemaForOr)
})

export type FilterRange = z.infer<typeof filterRangeSchema>
export type FilterPriceRange = z.infer<typeof filterPriceRangeSchema>
export type FilterChoose = Omit<
	z.infer<typeof filterChooseSchemaForAnd>,
	'type'
> & {
	type: FilterType.OR | FilterType.AND
}

export type FilterGroup = z.infer<typeof filterGroupSchema>
export type FilterGroupDropdown = z.infer<typeof filterGroupDropdownSchema>
