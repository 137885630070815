import { Feature, FeatureOptions } from '@wwt/entities/search-response'

export const getSelectedOptionInfoFromFeature = (
	feature: Feature
): FeatureOptions | undefined => {
	if ('options' in feature) {
		if (feature.options.length === 1) {
			return feature.options[0]
		}

		return feature.options.find(option => option.isSelected)
	}

	return feature.option
}
