import { type ReactNode, forwardRef } from 'react'

import { Button, type ButtonProps, Text } from '@chakra-ui/react'

import { FeatureIconMemo, type FeatureIconProps } from '../../FeatureIcon'

interface FeatureClickableButtonProps extends ButtonProps {
	basePropsForIcon: FeatureIconProps
	isExist?: boolean
	onClick?: () => void
	disableIcon?: boolean
	children: ReactNode
}

export const FeatureClickableButton = forwardRef<
	HTMLParagraphElement,
	FeatureClickableButtonProps
>(
	(
		{
			basePropsForIcon,
			isExist,
			onClick,
			disableIcon,
			noOfLines,
			textAlign,
			children,
			...props
		}: FeatureClickableButtonProps,
		ref
	) => {
		return (
			<Button
				variant="link"
				color="primary.100"
				leftIcon={
					!disableIcon ? (
						<FeatureIconMemo
							{...basePropsForIcon}
							color={isExist ? 'primary.100' : 'brand.100'}
						/>
					) : undefined
				}
				iconSpacing="0.75rem"
				_hover={{ bg: 'none' }}
				onClick={onClick}
				textDecorationThickness="0.0625rem"
				{...props}
			>
				<Text
					ref={ref}
					noOfLines={noOfLines}
					whiteSpace="normal"
					textAlign={textAlign}
				>
					{children}
				</Text>
			</Button>
		)
	}
)

FeatureClickableButton.displayName = 'FeatureClickableButton'
