import { memo, useMemo } from 'react'

import { Icon, IconProps } from '@chakra-ui/react'

import { getFeatureIcon } from '@wwt/shared/utils/getFeatureIcon'

export interface FeatureIconProps extends IconProps {
	groupIconName?: string
	featureIconName?: string
	optionIconName?: string
}

export const FeatureIcon = ({
	groupIconName,
	featureIconName,
	optionIconName,
	...props
}: FeatureIconProps) => {
	const IconForRender = useMemo(
		() => getFeatureIcon(groupIconName, featureIconName, optionIconName),
		[groupIconName, featureIconName, optionIconName]
	)

	if (!IconForRender) {
		return null
	}

	return (
		<Icon
			as={IconForRender}
			{...props}
		/>
	)
}

export const FeatureIconMemo = memo(FeatureIcon)
