import { useRef } from 'react'

import {
	Box,
	BoxProps,
	Tooltip,
	useDisclosure,
	useOutsideClick
} from '@chakra-ui/react'

import TooltipIcon from '@wwt/shared/assets/icons/outline/tip.svg'

interface TooltipsProps extends BoxProps {
	children: JSX.Element
}

export const TooltipBase = ({ children, ...props }: TooltipsProps) => {
	const { isOpen, onOpen, onClose, onToggle } = useDisclosure()
	const ref = useRef<HTMLDivElement>(null)

	useOutsideClick({
		ref: ref,
		handler: onClose
	})

	//FIXME: Maybe there are another way to fix this on mobile
	// Current fix related to this issue https://github.com/chakra-ui/chakra-ui/issues/7107#issuecomment-1364359979
	return (
		<Box
			ref={ref}
			display="inline-block"
			marginTop="-0.40625" // -6.5px
			marginLeft="0.3125" // 5px
			onMouseEnter={onOpen}
			onMouseLeave={onClose}
			onClick={onToggle}
			{...props}
		>
			<Tooltip
				label={children}
				maxW="20rem"
				maxH="29.375rem"
				openDelay={200}
				isOpen={isOpen}
			>
				<Box width="1rem">
					<TooltipIcon
						width="1rem"
						height="1rem"
						color="gray"
					/>
				</Box>
			</Tooltip>
		</Box>
	)
}
