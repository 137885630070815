import { z } from 'zod'

import {
	// filterGroupDropdownSchema,
	filterChooseSchemaForAnd,
	filterChooseSchemaForOr,
	filterGroupSchema,
	filterPriceRangeSchema,
	filterRangeSchema
} from './FilterVariants.ts'

export const filterItemSchema = z.discriminatedUnion('type', [
	filterRangeSchema,
	filterPriceRangeSchema,
	filterChooseSchemaForAnd,
	filterChooseSchemaForOr,
	filterGroupSchema
	// filterGroupDropdownSchema
])

export type FilterItem = z.infer<typeof filterItemSchema>
