import { type ReactNode, forwardRef } from 'react'

import { Box, Text, type TextProps } from '@chakra-ui/react'

import { TooltipBase } from '../../../Tooltips/TooltipBase'
import { FeatureIconMemo, type FeatureIconProps } from '../../FeatureIcon'
import { FeatureTooltip } from '../FeatureTooltip'

interface FeatureButtonProps extends TextProps {
	basePropsForIcon: FeatureIconProps
	isExist?: boolean
	hasTooltip?: boolean
	tooltipImageUrl?: string
	tooltipTitle?: string
	tooltipText?: string
	onMouseLeave?: () => void
	onMouseEnter?: () => void
	children: ReactNode
}

export const FeatureButton = forwardRef<
	HTMLParagraphElement,
	FeatureButtonProps
>(
	(
		{
			basePropsForIcon,
			isExist,
			hasTooltip,
			tooltipTitle,
			tooltipImageUrl,
			tooltipText,
			onMouseLeave,
			onMouseEnter,
			children,
			...props
		}: FeatureButtonProps,
		ref
	) => {
		return (
			<>
				<Text
					ref={ref}
					whiteSpace="normal"
					{...props}
				>
					{children}
				</Text>
				{hasTooltip && tooltipTitle && (
					<Box
						onMouseLeave={onMouseLeave}
						onMouseEnter={onMouseEnter}
					>
						<TooltipBase>
							<FeatureTooltip
								title={tooltipTitle}
								text={tooltipText}
								imageUrl={tooltipImageUrl}
								iconComponent={() => (
									<FeatureIconMemo
										{...basePropsForIcon}
										color={isExist ? 'gray.500' : 'brand.100'}
									/>
								)}
							/>
						</TooltipBase>
					</Box>
				)}
			</>
		)
	}
)

FeatureButton.displayName = 'FeatureButton'
