import { Storage } from './types'

let storage: Storage

export const setItem = (key: string, value: string) => {
	if (storage === undefined) {
		throw new Error('Storage is undefined')
	}
	if (typeof storage.setItem !== 'function') {
		throw new Error('Storage should implement setItem method')
	}
	storage.setItem(key, value)
}

export const getItem = (key: string) => {
	if (storage === undefined) {
		throw new Error('Storage is undefined')
	}
	if (typeof storage.setItem !== 'function') {
		throw new Error('Storage should implement setItem method')
	}
	return storage.getItem(key)
}

export const clearStorage = () => {
	storage.clearStorage()
}

export const setStorage = (instance: Storage) => {
	storage = instance
}
