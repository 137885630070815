import { ReactElement, memo, useCallback } from 'react'

import { Box, Radio, RadioGroup, RadioGroupProps } from '@chakra-ui/react'

import { ModalGroupOption } from '../types'

interface ModalRadioGroupProps extends Omit<RadioGroupProps, 'children'> {
	options: ModalGroupOption[]
	activeElement: ModalGroupOption['id']
	onChange: (newActiveElements: ModalGroupOption['id']) => void
	wrapper: ReactElement
}

export const ModalRadioGroup = ({
	options,
	activeElement,
	onChange,
	wrapper = <Box />,
	...props
}: ModalRadioGroupProps) => {
	const onValueChange = useCallback(
		(newActiveElement: string | number) => {
			if (typeof newActiveElement === 'number') {
				return
			}

			onChange(newActiveElement)
		},
		[onChange]
	)

	return (
		<RadioGroup
			value={activeElement}
			onChange={onValueChange}
			{...props}
		>
			<wrapper.type {...wrapper.props}>
				{options.map(option => {
					return (
						<Box
							maxW={{ base: '100%', md: '48%', xl: '32%' }}
							w="100%"
							minWidth="17.5rem" // 280px
							as="article"
							key={option.id}
							{...option.additionalProps}
						>
							<Radio
								title={option.description || undefined}
								value={option.id}
								alignItems="flex-start"
							>
								{typeof option.name === 'string' ? (
									<span>{option.name}</span>
								) : (
									<option.name.type
										key={option.name}
										{...option.name.props}
									/>
								)}
								<span>{option.slot}</span>
							</Radio>
						</Box>
					)
				})}
			</wrapper.type>
		</RadioGroup>
	)
}

export const ModalRadioGroupMemo = memo(ModalRadioGroup)
