import { z } from 'zod'

export const filterRangeParamsSchema = z.object({
	/**
	 * Minimum value for the range filter. The default value is typically set to 0.
	 */
	rangeMin: z.number().optional(),
	/**
	 * Maximum value for the range filter. This value sets the upper limit for the filter.
	 */
	rangeMax: z.number().optional(),
	/**
	 * Step value for the range increments. It defines the increment step for each move in the range filter.
	 */
	rangeStep: z.number().optional(),
	/**
	 * Indicates whether there are two thumb controls for the range. Default is false, indicating a single thumb control.
	 */
	isThumbTwo: z.boolean().optional(),
	/**
	 * Allows selection of a value greater than the specified maximum. Useful for scenarios where users can select a value like '2000+' without a specific upper limit.
	 */
	isMaxPlus: z.boolean().optional(),

	/**
	 * Recommended value for this range filter, suggesting a threshold that might trigger a specific action or recommendation.
	 */
	recommendedValue: z.number().optional(),

	/**
	 * Indicates if the recommendation should apply when the actual value is above the recommended value.
	 */
	isRecommendedAboveValue: z.boolean().optional()
})

export type FilterRangeParams = z.infer<typeof filterRangeParamsSchema>
