import { ReactNode } from 'react'

import { Box, BoxProps, HStack } from '@chakra-ui/react'

import { FeatureIconMemo, type FeatureIconProps } from './FeatureIcon.tsx'
import {
	FeatureButton,
	FeatureClickableButton
} from './components/FeatureButton'

interface FeatureProps extends BoxProps {
	label: string | ReactNode
	isClickable?: boolean
	isExist?: boolean
	onClick?: () => void
	disableIcon?: boolean
	hasTooltip?: boolean
	tooltipImageUrl?: string
	tooltipTitle?: string
	tooltipText?: string
	iconValues: FeatureIconProps
}

export const Feature = ({
	label,
	isClickable = false,
	isExist = true,
	onClick,
	disableIcon = false,
	hasTooltip = false,
	tooltipImageUrl,
	tooltipTitle,
	tooltipText,
	iconValues,
	...props
}: FeatureProps) => {
	const basePropsForIcon: FeatureIconProps = {
		groupIconName: iconValues.groupIconName,
		featureIconName: iconValues.featureIconName,
		optionIconName: iconValues.optionIconName,
		width: '1.5rem',
		height: '1.5rem'
	}

	return (
		<Box
			position="relative"
			display="flex"
			{...props}
		>
			{isClickable ? (
				<FeatureClickableButton
					basePropsForIcon={basePropsForIcon}
					isExist={isExist}
					onClick={onClick}
					disableIcon={disableIcon}
					textAlign="left"
				>
					{label}
				</FeatureClickableButton>
			) : (
				<HStack
					color="gray.500"
					w="full"
				>
					{!disableIcon ? (
						<FeatureIconMemo
							{...basePropsForIcon}
							color={isExist ? 'gray.500' : 'brand.100'}
						/>
					) : undefined}

					<FeatureButton
						basePropsForIcon={basePropsForIcon}
						hasTooltip={hasTooltip}
						tooltipImageUrl={tooltipImageUrl}
						tooltipTitle={tooltipTitle}
						tooltipText={tooltipText}
						textAlign="left"
					>
						{label}
					</FeatureButton>
				</HStack>
			)}
		</Box>
	)
}
