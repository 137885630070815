import { Box, Flex, Icon, Text } from '@chakra-ui/react'

import { IconComponent } from '@wwt/entities/base'

interface ToolTipWithIconProps {
	title: string
	text: string
	icon: IconComponent
}

export const ToolTipWithIcon = ({
	title,
	text,
	icon
}: ToolTipWithIconProps) => {
	return (
		<Flex
			flexDirection="column"
			gap="1rem"
			minW="11.375rem"
			maxH="14.375rem"
			maxW="20rem"
		>
			<Flex
				borderRadius="max"
				w="3rem"
				h="3rem"
				border="0.0625rem solid"
				borderColor="gray.200"
				justifyContent="center"
				alignItems="center"
			>
				<Icon
					as={icon}
					w="1.5rem"
					h="1.5rem"
				/>
			</Flex>
			<Flex
				flexDirection="column"
				gap="0.5rem"
			>
				<Box textStyle="body-text-2">{title}</Box>
				<Text
					textAlign="start"
					textStyle="body-text-7"
				>
					{text}
				</Text>
			</Flex>
		</Flex>
	)
}
