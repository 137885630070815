import { ListTypes } from '../types'

export const listIds: Record<ListTypes, number[]> = {
	[ListTypes.USERS]: [7],
	[ListTypes.NPS]: [8],
	[ListTypes.MOBILE]: [10],
	[ListTypes.REQUEST_DISCOUNT]: [9],
	[ListTypes.TUTORIAL_SCORE]: [11],
	[ListTypes.RESERVATION_FORM]: [12]
}
