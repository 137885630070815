import { create } from 'zustand'

import {
	getItem as getStorageItem,
	setItem as setStorageItem
} from '../storage'

export enum TutorialStatus {
	SKIPPED = 'skipped',
	GOING = 'going',
	REVIEW = 'review',
	ENDED = 'ended'
}

export const STATUS_KEY = 'tutorialStatus'
export const STEP_INDEX_KEY = 'stepIndex'

interface TutorialState {
	stepIndex: number
	tutorialStatus: TutorialStatus | null
	justFinishedTutorial: boolean
	setNextStep: (increment?: number) => void
	setTutorialStatus: (status: TutorialStatus) => void
	setJustFinishedTutorial: (value: boolean) => void
	getTutorialStatus: () => Promise<void>
	getTutorialStepIndex: () => Promise<void>
	reset: () => void
}

export const useTutorialStore = create<TutorialState>(set => ({
	stepIndex: 0,
	tutorialStatus: null,
	justFinishedTutorial: false,
	setJustFinishedTutorial: (value: boolean) =>
		set({ justFinishedTutorial: value }),
	setNextStep: async (increment = 1) => {
		set(state => {
			if (state.tutorialStatus !== TutorialStatus.GOING) {
				return state
			}
			const newStepIndex =
				state.stepIndex >= 6 ? 0 : state.stepIndex + increment
			setStorageItem(STEP_INDEX_KEY, newStepIndex.toString())
			return { stepIndex: newStepIndex }
		})
	},
	setTutorialStatus: async (status: TutorialStatus) => {
		setStorageItem(STATUS_KEY, status)
		set({ tutorialStatus: status })
	},
	getTutorialStatus: async () => {
		set({
			tutorialStatus: (await getStorageItem(STATUS_KEY)) as TutorialStatus
		})
	},
	getTutorialStepIndex: async () => {
		set({ stepIndex: Number(await getStorageItem(STEP_INDEX_KEY)) || 0 })
	},
	reset: async () => {
		setStorageItem(STEP_INDEX_KEY, '0')
		set({ tutorialStatus: null, stepIndex: 0 })
	}
}))
