import { memo } from 'react'

import { Box } from '@chakra-ui/react'

import { useLocalDebug } from '../store'

interface SortDebugValueProps {
	integrationPriority?: number | undefined
	wwtPriority?: number | undefined
}

/**
 *
 *
 * @author Ivan Shchedrovskyi
 */
export const SortDebugValue = memo(
	({ integrationPriority, wwtPriority, ...props }: SortDebugValueProps) => {
		const { sortPriorityShow } = useLocalDebug()

		if (!sortPriorityShow) return null

		return (
			<Box
				display="inline"
				px="0.25rem"
				minH="25px"
				minW="10px"
				background={
					integrationPriority === undefined && wwtPriority === undefined
						? 'red.100'
						: 'blue.100'
				}
				{...props}
			>
				{integrationPriority !== undefined && (
					<Box display="inline">{integrationPriority}</Box>
				)}
				{wwtPriority !== undefined && <Box display="inline">{wwtPriority}</Box>}
			</Box>
		)
	}
)

SortDebugValue.displayName = 'SortDebugValue'
