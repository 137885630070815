import { useId, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
	Box,
	Button,
	Radio,
	RadioGroup,
	SimpleGrid,
	VStack
} from '@chakra-ui/react'
import { useSelect } from 'downshift'

import { ModalHeader } from '../Modal'
import type { SelectProps } from './Select'

interface SelectModalProps<T>
	extends Pick<
			SelectProps<T>,
			| 'placeholder'
			| 'prefix'
			| 'items'
			| 'itemToKey'
			| 'itemToString'
			| 'render'
		>,
		Pick<ReturnType<typeof useSelect<T>>, 'getItemProps' | 'getMenuProps'> {
	onClose: () => void
	onSelect: (item: T | null) => void
	selectedItem: T | null
}

export const SelectModal = <T,>({
	onClose,
	placeholder,
	prefix,
	items,
	selectedItem,
	itemToKey,
	itemToString,
	render,
	onSelect,
	getItemProps,
	getMenuProps
}: SelectModalProps<T>) => {
	const { t } = useTranslation()
	const id = useId()
	const [selected, setSelected] = useState(
		selectedItem ? itemToString?.(selectedItem) : undefined
	)

	return (
		<VStack
			as="section"
			p="2rem"
			position="relative"
			alignItems="flex-start"
			justifyContent={{ base: 'space-between', md: 'unset' }}
			minH={{ base: '97vh', md: 'unset' }}
		>
			<Box w="100%">
				<ModalHeader
					text={placeholder ?? prefix ?? ''}
					mb={{ base: '1.5rem', md: '2rem' }}
				/>
				<Box
					w="100%"
					mb="1rem"
				>
					<RadioGroup
						name={id}
						value={selected}
						onChange={setSelected}
					>
						<SimpleGrid
							columns={{ base: 1, md: 2 }}
							spacing="1rem"
							{...getMenuProps({}, { suppressRefError: true })}
						>
							{items.map((item, index) => {
								const props = getItemProps({ item, index })
								return (
									<>
										<Radio
											key={itemToKey?.(item) ?? itemToString?.(item) ?? index}
											value={itemToString?.(item)}
											isDisabled={props['aria-disabled']}
											{...props}
										>
											{itemToString?.(item)}
										</Radio>
										{render?.(item, props['aria-selected'])}
									</>
								)
							})}
						</SimpleGrid>
					</RadioGroup>
				</Box>
			</Box>

			<Button
				w="100%"
				variant="solid"
				colorScheme="brand"
				size="md"
				mb={{ base: '3rem', md: 'unset' }}
				onClick={() => {
					onSelect(
						items.find(item => itemToString?.(item) === selected) ?? null
					)
					onClose()
				}}
			>
				{t('apply')}
			</Button>
		</VStack>
	)
}
