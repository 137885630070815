import { memo, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, GridItem } from '@chakra-ui/react'
import { useMobileBreakpoint } from '@wwt/custom/chakra-ui'

import { Feature } from '@wwt/entities/search-response'
import DownArrowIcon from '@wwt/shared/assets/icons/outline/arrow-down.svg'

import {
	FeatureByFeatureData,
	FeatureByFeatureDataProps
} from './FeatureByFeatureData'

interface FeatureListProps
	extends Omit<FeatureByFeatureDataProps, 'feature' | 'onClick'> {
	features: Feature[]
	additionalForKey?: string
	onClick?: (featureName: string) => void
	maxFeatures0nMobile?: number
}

export const FeaturesList = ({
	features,
	additionalForKey,
	onClick,
	maxFeatures0nMobile = 6,
	...props
}: FeatureListProps) => {
	const { t } = useTranslation()
	const [showAllOptions, setShowAllOptions] = useState(false)
	const isManyOptions = features.length > maxFeatures0nMobile

	const isMobile = useMobileBreakpoint()

	const featuresForRender = useMemo(() => {
		return features.slice(
			0,
			!showAllOptions && isMobile ? maxFeatures0nMobile : features.length
		)
	}, [features, showAllOptions, isMobile])

	return (
		<>
			{featuresForRender.map(feature => (
				<GridItem key={additionalForKey + feature.name}>
					<FeatureByFeatureData
						{...props}
						feature={feature}
						onClick={() => onClick?.(feature.name)}
					/>
				</GridItem>
			))}

			{!showAllOptions && isMobile && isManyOptions && (
				<Button
					variant="link"
					onClick={() => setShowAllOptions(true)}
					rightIcon={
						<DownArrowIcon
							width="1rem"
							height="1rem"
							color="black"
						/>
					}
					color="primary.100"
					w="6.25rem"
					_hover={{
						color: 'gray.300'
					}}
					_active={{
						color: 'gray.500'
					}}
				>
					{t('seeMore')}
				</Button>
			)}
		</>
	)
}

export const FeaturesListMemo = memo(FeaturesList)
